:root {
    --foreground-text: #ffffff;/* yellow gray */
    --background-wb: #080d10;
    --highlight-text: #5899e2;/* darker bright blue */
    --alternate-highlight: #ffc81f;/* lighter blue */
    --yellow-highlight: #ffc81f;
    --highlight-green: #5cf05c;
    --alternate-highlight-a1: rgb(255,212,42);
    --alternate-highlight-a2: rgb(255,227,119);
    --background-1: #0a1014;
    --background-2: #142129; /* #2d2d2d; */ /* usually darker of two */
    --background-3: #2a2a2a; /*used for shadows, sometimes the same as #2 */
    --bg3: rgba(26, 27, 38, 0.3); /* #1a1b26; /* dark purple*/

    --green-foreground-text: #C5C6C7;/* #e4e5ed; */
    --green-foreground-text: #ffffff; /* #e4e5ed; */
    --green-background-wb: #000000;
    --green-highlight-text: #66FCF1;
    --green-background-1: #1F2833;
    --green-background-2: #0B0C10; /* #2d2d2d; */ /* usually darker of two */
    --green-background-3: #45A29E; /*used for shadows, sometimes the same as #2 */



}

[data-theme="light"] {
  --foreground-text: #1a1b26;/* yellow gray */
  --foreground-text: #0B0C10; /* #e4e5ed; */
  --background-wb: #C5C6C7;
  --highlight-text: #5094ff; /* bright blue */
  --alternate-highlight: #ffea4a; /* bright yellow*/
  --background-1: #C5C6C7; /* dark purple*/
  --background-2: #ffffff; /* #2d2d2d; */ /* usually darker of two */
  --background-3: #6a779c; /*used for shadows, sometimes the same as #2 */

  --green-foreground-text: #C5C6C7;/* #e4e5ed; */
  --green-foreground-text: #ffffff; /* #e4e5ed; */
  --green-background-wb: #000000;
  --green-highlight-text: #66FCF1;
  --green-background-1: #1F2833;
  --green-background-2: #0B0C10; /* #2d2d2d; */ /* usually darker of two */
  --green-background-3: #45A29E; /*used for shadows, sometimes the same as #2 */
}

@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@300;400;500;600;700&family=Londrina+Solid:wght@100;300;400;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@300;400;500;600&family=Londrina+Solid:wght@100;300;400;900&display=swap');

body {
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: "Barlow", "Londrina Solid", Sans-serif;
  background-color: var(--background-2);

  overflow-x: hidden;
  overflow-y: visible;
}

a {
  color: var(--highlight-text);
}

a:hover {
  color: var(--foreground-text);
  text-decoration: underline;
}

p,ul,ol {
  color: var(--foreground-text);
  font-size: 1em;
  font-weight: normal;
  margin: 0.6em;
}
h1 {
    font-family: "Londrina Solid", Sans-serif;
    color: var(--alternate-highlight);
    font-size: 2.2em;
    letter-spacing: 1.3px;
    width: 90%;
    min-width: 350px;
    margin: 0 auto;
    margin-bottom: 0.7em;
    text-align: center;
}
h2 {
  font-family: "Londrina Solid", Sans-serif;
  text-align: center;
  font-size: 2em;
  color: var(--highlight-text);
  font-weight: 600;
  margin: 0.5em;
}
h3 {
  font-family: "Londrina Solid", Sans-serif;
  text-align: center;
  font-size: 1.4em;
  letter-spacing: 1.5px;
  color: #ffffff;
  font-weight: 600;
  margin: 10px;
  margin-top: 20px;
}
h4 {
  font-family: "Londrina Solid", Sans-serif;
  text-align: left;
  font-size: 1.2em;
  letter-spacing: 1.5px;
  color: #ffffff;
  font-weight: 600;
  margin: 6px;
  margin-top: 15px;
}
li {
  color: var(--foreground-text);
  font-size: 1em;
  font-weight: normal;
  margin-bottom: 2px;
}

.highlight {
  color: var(--highlight-text);
}
.highlight2 {
  position: relative;
  font-size: 1.1em;
  color: var(--yellow-highlight);
}
.strikeout {
  text-decoration: line-through;
}

#root {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0px;
}

.logo {
  padding: 3px 3px;
  color: var(--foreground-text);
  width: 100%;
}


.logo-img {
  max-width: 200px;
}

.logo__img-login {
  max-width: 260px;
}




.link {
  color: var(--foreground-text);
  font-size: 13px;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: inherit;
}

.link-sm {
  font-size: 14px;
}

.link-md {
  font-size: 18px;
}

.link-lg {
  font-size: 20px;
}

.link-xl {
  font-size: 30px;
}

.input {
  padding: 10px 15px;
  font-size: 13px;
  border: 1px solid var(--foreground-text);
  border-radius: 10px;
  background-color: var(--background-2);
}

.input:focus{
  border-color: var(--highlight-text);
}

input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }


.button {
  padding: 12px 12px;
  background-color: var(--highlight-text);
  color: var(--background-2);
  border-radius: 10px;
  font-size: 1em;
  font-weight: 500;
  line-height: 0.95em;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  border: none;
  transition: background-color 0.3s ease;
}

.button.primary {
  color: var(--background-2);
  background-color: var(--alternate-highlight);
}
.button:hover {
  color: var(--background-2);
  background-color: var(--foreground-text);
  text-decoration: none;
}
.button.primary:hover {
  color: var(--background-2);
  background-color: var(--foreground-text);
  text-decoration: none;
}
.button.primary2 {
  color: var(--background-2);
  background-color: var(--highlight-green);
  padding: 0.8em;
  font-size: 1.2em;
}
.button.primary2:hover {
  color: var(--background-2);
  background-color: var(--foreground-text);
  text-decoration: none;
}

.button.decoy {
  color: var(--background-2);
  background-color: var(--foreground-text);
}
.button.decoy:hover {
  color: var(--background-2);
  background-color: var(--alternate-highlight);
  text-decoration: none;
}
.button.green {
  color: var(--highlight-2);
  background-color: var(--highlight-green);
}
.button.green:hover {
  color: var(--background-2);
  background-color: var(--foreground-text);
  text-decoration: none;
}
.button.decoy2 {
  color: #aaaaaa;
  background-color: #222222;
  opacity: 0.6;
}

.form_label {
  color: white;
  font-weight: bolder;
  margin-top: 1px;
  margin-bottom: 2px;
  font-size: 0.9em;
}

input[type=button], input[type=select], input[type=submit] {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius: 10px;
}

.prompt-message {
    color: var(--alternate-highlight);
    font-size: 1.8em;
    white-space: nowrap;
}

.agreement_box {
  max-width: 600px;
  width:90%;
  margin: 0 auto;
  margin: 20px 0;
  padding: 0.9em;
  color: var(--foreground-text);
  text-align: center;
  padding-bottom: 50px;
  background-color: var(--background-1);
  box-shadow: 1px 1px 15px var(--background-3);
}
.agreement_box h1{
  font-size: 1.4em;
}

.agreement_box .button{
  margin-top: 15px;
}

.agreement_box .button:disabled{
  background-color: #ccc !important;
}

.canvas {
	display: block;
	position: absolute;
	left: 0px;
	top: 0px;
}

.footer {
  position: fixed;
  z-index: -1;
  overflow: hidden;
  bottom:0;
  width:100%;
  min-height: 300px;
  /*
  background: url('/mountains2.svg');
  background-repeat: repeat-x;
  background-position: bottom;
   */
}

@media (min-width:320px) {
    /* smartphones, iPhone, portrait 480x320 phones */
    p,ul,ol,li {
      font-size: 1em;
      line-height: 1.1em;

    }
    ul {
      padding-inline-start: 20px;
    }

        h2 {
          margin: 2px;
        }
        .prompt-message {
            font-size: 1.4em;
            margin-top: 2px;
        }
}
@media (min-width:481px) {
    /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
}
@media (min-width:641px) {
    /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
    p,ul,ol {
      font-size: 1.2em;
      line-height: 1.3em;
    }

    h2 {
      margin: 2px;
    }
    .prompt-message {
        font-size: 1.4em;
        margin-top: 2px;
    }

}
 @media (min-width:961px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
    p,ul,ol {
      font-size: 1em;
      line-height: 1.4em;
    }

}
 @media (min-width:1025px) {
    /* big landscape tablets, laptops, and desktops */
    p,ul,ol {
      font-size: 1em;
      line-height: 1.4em;
    }
}
 @media (min-width:1281px) {
    /* hi-res laptops and desktops */
    p,ul,ol {
      font-size: 1em;
      line-height: 1.2em;
    }
}
